import Image from 'next/image'
import NextLink from 'next/link'
import { Box, BoxProps, useBreakpointValue } from '@chakra-ui/react'
import { BaseProps, ObjectFit } from './types'
import { imageOptimization } from '../general/components/image-optimization'

export interface BannerImageProps extends BaseProps {
  priority?: boolean
  root?: BoxProps
  imageBox?: BoxProps
  href?: string | null
  imageDesktop?: {
    src?: string
    alt?: string
    objectFit?: ObjectFit
  }

  imageMobile?: {
    src?: string
    alt?: string
    objectFit?: ObjectFit
  }
  overlayBackground?: string
  objectFit?: ObjectFit
  fillImage?: boolean
  disableMinHeight?: boolean
  imageHeight?: number
  imageWidth?: number
}

const imageBase: BoxProps = {
  position: 'relative',
  width: '100%',
  minHeight: '400px',
  overflow: 'hidden',
}

export const BannerImage = ({
  priority = false,
  imageDesktop,
  imageMobile,
  imageBox,
  overlayBackground,
  root,
  objectFit = 'cover',
  isMobileDevice,
  href,
  fillImage = true,
  disableMinHeight = false,
  imageHeight,
  imageWidth,
}: BannerImageProps & BoxProps) => {
  const isMobileViewport = useBreakpointValue({ base: true, lg: false }, { fallback: isMobileDevice ? 'base' : 'lg' })
  const isMobile = typeof window === 'undefined' ? isMobileDevice : isMobileViewport

  if (!imageDesktop?.src && !imageMobile?.src) {
    return null
  }

  if (disableMinHeight) {
    delete imageBase.minHeight
  }

  const objectFitConfig: ObjectFit = isMobile
    ? imageMobile?.objectFit || objectFit
    : imageDesktop?.objectFit || objectFit

  const CoreBannerImage = (
    <>
      <Box display={['none', 'none', 'block']} {...imageBase} {...imageBox}>
        {fillImage && (
          <Image
            src={isMobile ? imageOptimization(imageMobile?.src) : imageOptimization(imageDesktop?.src) || ''}
            alt={isMobile ? imageMobile?.alt : imageDesktop?.alt || ''}
            fill
            priority={priority}
            style={{
              objectFit: objectFitConfig,
            }}
          />
        )}
        {!fillImage && imageWidth && imageHeight && (
          <Image
            src={isMobile ? imageOptimization(imageMobile?.src) : imageOptimization(imageDesktop?.src) || ''}
            alt={isMobile ? imageMobile?.alt : imageDesktop?.alt || ''}
            priority={priority}
            width={imageWidth}
            height={imageHeight}
            style={{
              objectFit: objectFitConfig,
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        )}
        {overlayBackground && (
          <Box position="absolute" top="0" left="0" width="100%" height="100%" background={overlayBackground} />
        )}
      </Box>
    </>
  )

  return href ? (
    <Box as={NextLink} href={href} display="flex" alignItems="stretch" {...root}>
      {CoreBannerImage}
    </Box>
  ) : (
    CoreBannerImage
  )
}
